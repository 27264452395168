import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  BaseForm,
  ModuleKeywords,
  TaskTemplateDto,
  getEnumOptions,
  humanizeCasedString,
  isNullObj,
} from '@shared/classes';
import { KendoEditorDropdownToolbarData } from '@shared/components/ui/input-fields/kendo-editor/kendo-editor.component';
import { ViewModeService } from '@shared/services';
import { TaskTemplatesDataService } from '../../services/data/task-templates-data.service';

@Component({
  selector: 'app-task-template-item-form',
  templateUrl: './task-template-item-form.component.html',
  styleUrls: ['./task-template-item-form.component.scss'],
})
export class TaskTemplateItemFormComponent extends BaseForm<TaskTemplateDto> implements OnInit {
  priorityOptions = getEnumOptions(TaskTemplateDto.PriorityEnum);
  moduleList = getEnumOptions(TaskTemplateDto.ModuleEnum);
  contextVariables: any[] = [];
  customDropdownData: KendoEditorDropdownToolbarData[] = [];
  invalidFormDropdownData: KendoEditorDropdownToolbarData[] = [{ text: 'Missing Data Key!', value: null }];
  loadingDropdownData: KendoEditorDropdownToolbarData[] = [{ text: 'Please wait...', value: null }];
  get currentCustomDropdownData() {
    if (!this.data?.key) {
      return this.invalidFormDropdownData;
    }
    if (!this.isLoadingContextSuggestions && this.customDropdownData?.length > 0 && !!this.data?.key) {
      return this.customDropdownData;
    } else {
      return this.loadingDropdownData;
    }
  }
  constructor(
    public viewModeService: ViewModeService,
    private requestService: TaskTemplatesDataService
  ) {
    super(viewModeService, ModuleKeywords.TaskTemplate);
  }

  ngOnInit(): void {
    this.loadContextSuggestions();
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl({ value: null, disabled: true }, Validators.required),
      // key: new FormControl(null, Validators.required),
      module: new FormControl({ value: null, disabled: true }),
      titleTemplate: new FormControl(null, Validators.required),
      descriptionTemplate: new FormControl(null),
      dueDateDays: new FormControl(null),
      priority: new FormControl(TaskTemplateDto.PriorityEnum.Normal),
      // systemGenerated: new FormControl({value:false,disabled:true}),
      // isDefault: new FormControl(false),
    });
  }
  isLoadingContextSuggestions = false;
  loadContextSuggestions() {
    if (isNullObj(this?.data?.key)) return;
    this.isLoadingContextSuggestions = true;
    this.requestService
      .getContextValues({
        params: { key: this.data.key },
        showLoading: false,
        showMsg: false,
        extras: { cacheKey: `taskContext_${this.data?.key}` },
      })
      .subscribe({
        next: (res) => {
          // this.contextVariables = getOptionsFromStringArray(res);
          this.contextVariables = res.map((item) => {
            return {
              label: humanizeCasedString(item),
              // icon: 'pi pi-refresh',
              command: () => {
                this.onSelectOption(`$${item}`);
              },
            };
          });
          this.customDropdownData = res.map((item) => {
            return {
              text: humanizeCasedString(item),
              value: `$${item}`,
            };
          });
          this.isLoadingContextSuggestions = false;
        },
        error: (err) => {
          this.isLoadingContextSuggestions = false;
        },
      });
  }
  currentEditorSelectionIndex = 0;
  currentEditor = null;
  onEditorContextMenuClick(event, editor) {
    // const selection = editor?.quill?.getSelection();
    // this.currentEditorSelectionIndex = selection?.index || 0;
    // // editor?.quill?.insertText(selection.index, 'ABCD');
    // this.currentEditor = editor;
    this.loadContextSuggestions();
  }
  onSelectOption(option) {
    if (this.currentEditor) {
      // const selection = this.currentEditor?.quill?.getSelection();
      // this.currentEditor?.quill?.insertText(selection?.index || this.currentEditorSelectionIndex, option);
    }
  }
}
